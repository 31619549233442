import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHighlight } from '../Service/Api';

const Highlights = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHighlight();
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Polling to check for updates dynamically
    const interval = setInterval(fetchData, 30000); // Fetch updates every 30 seconds
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  // Check if there is valid data to display
  const hasValidData = data.some(
    (item) => item.title || (item.attachments && item.attachments.length > 0)
  );

  return (
    <>
      {hasValidData && (
        <div className="highlights">
          <img
            src="https://webapi.entab.info/api/image/JRPSB/public/Images/announcement.png"
            alt="Jai Rani Public School"
          />
          <marquee
            onMouseOver={(event) => event.currentTarget.stop()}
            onMouseOut={(event) => event.currentTarget.start()}
          >
            {data.map((item, index) => (
              <p key={index}>
                {item.title}{' '}
                {item.attachments && item.attachments.length > 0 && (
                  <Link
                    to={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                    target="_blank"
                  >
                    <i className="bi bi-paperclip"></i>
                  </Link>
                )}
                {' | '}
              </p>
            ))}
          </marquee>
        </div>
      )}
    </>
  );
};

export default Highlights;
