import React, {useEffect}  from "react"; 
import { Link } from 'react-router-dom'; 
import Header from '../Component/Header'
import Footer from '../Component/Footer' 

const Faculties = () => { 
    useEffect(() => {
        document.title = 'Faculties - Jai Rani Public School, Balussery';  
    }, []);   
    return (
        <>
            <Header />
<div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>About </li>
                    <li>Faculties</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Faculties</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                             
                               <div className="table-responsive maintable">
                                <table className="table">
                                 <thead>
                                  <tr> 
                                   <th width="200px">  Sl.No   </th>
                                   <th width="500px">  Teacher's Name  </th> 
                                    <th width="500px">     Qualification </th>
                                     <th width="500px"> Designation  </th> 
                                     <th width="500px">DOJ</th>
                                     </tr> 
                                     </thead> 
                                     <tbody>
                                     <tr> <td> 1 </td> <td> Sr. PHILOMINA THOMAS </td> {/*  <td> 30-05-1959 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> Sr.Principal </td> <td> 01-06-2007 </td> </tr> 
                                     <tr> <td> 2 </td> <td> Sr. SIMY SCARIA </td> {/*  <td> 13-04-1974 </td> */}{/* <td> Female </td>*/} <td> M S W, B Ed </td> <td> Principal </td> <td> 01-06-2021 </td> </tr> 
                                     <tr> <td> 3 </td> <td> SONIMON XAVIER </td> {/*  <td> 12-10-1975 </td> */}{/* <td> Male </td>*/} <td> M A, B Ed </td> <td> Vice Principal </td> <td> 06-06-2019 </td> </tr> 
                                     <tr> <td> 4 </td> <td> RIJI K </td> {/*  <td> 25-05-1979 </td> */}{/* <td> Female </td>*/} <td> B Sc, B Ed </td> <td> TGT </td> <td> 01-06-2009 </td> </tr> 
                                     <tr> <td> 5 </td> <td> SHIJI E K </td> {/*  <td> 29-05-1976 </td> */}{/* <td>  Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 01-06-2009 </td> </tr> 
                                     <tr> <td> 6 </td> <td> SREELEKHA MOHAN </td> {/*  <td> 31-05-1975 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> PRT </td> <td> 01-06-2009 </td> </tr> 
                                     <tr> <td> 7 </td> <td> SINDU V V K </td> {/*  <td> 12-11-1976 </td> */}{/* <td> Female </td>*/} <td> B Sc, B Ed </td> <td> PRT </td> <td> 01-06-2010 </td> </tr> 
                                     <tr> <td> 8 </td> <td> SARITHA K </td> {/*  <td> 07-03-1972 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 01-06-2010 </td> </tr> 
                                     <tr> <td> 9 </td> <td> DIVYA M P </td> {/*  <td> 01-01-1984 </td> */}{/* <td> Female </td>*/} <td> B Sc, PGDCA </td> <td> PRT </td> <td> 01-10-2010 </td> </tr> 
                                     <tr> <td> 10 </td> <td> SOBHA P M </td> {/*  <td> 19-01-1972 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> PRT </td> <td> 03-01-2011 </td> </tr> 
                                     <tr> <td> 11 </td> <td> PRABEENA K </td> {/*  <td> 10-05-1980 </td> */}{/* <td> Female </td>*/} <td> B A, B Ed </td> <td> TGT </td> <td> 01-06-2011 </td> </tr> 
                                     <tr> <td> 12 </td> <td> VIDYA V </td> {/*  <td> 31-05-1980 </td> */}{/* <td> Female </td>*/} <td> B Sc, B Ed </td> <td> PRT </td> <td> 01-06-2011 </td> </tr> 
                                     <tr> <td> 13 </td> <td> ASHA N P </td> {/*  <td> 28-01-1981 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 01-06-2011 </td> </tr> 
                                     <tr> <td> 14 </td> <td>SOUMYA M K </td> {/*  <td>10-05-1986 </td>  */}{/*<td>Female </td>*/} <td>B A, B Ed, PGDCA </td> <td>TGT </td> <td>01-06-2012 </td> </tr> 
                                     <tr> <td>15 </td> <td>REENA JOHN </td> {/*  <td>25-05-1970 </td>  */}{/*<td>Female </td>*/} <td>B Sc, B Ed </td> <td>TGT </td> <td>03-06-2013 </td> </tr> 
                                     <tr> <td>16 </td> <td>SREEKALA P P </td> {/*  <td>28-05-1981 </td>  */}{/*<td>Female </td>*/} <td>M A, B Ed </td> <td>PRT </td> <td>01-11-2013 </td> </tr> 
                                     <tr> <td>17 </td> <td>JISHA RAJ </td> {/*  <td>19-02-1981 </td>  */}{/*<td>Female </td>*/} <td>B Sc, B Ed </td> <td>PRT </td> <td>02-06-2014 </td> </tr> 
                                     <tr> <td>18 </td> <td>LINEESH K T </td> {/*  <td>31-05-1978 </td>  */}{/*<td>Male </td>*/} <td>M A, B Ed </td> <td>TGT </td> <td>01-06-2015 </td> </tr> 
                                     <tr> <td>19 </td> <td>SREEDHANYA P </td> {/*  <td>30-05-1989 </td>  */}{/*<td>Female </td>*/} <td>B A, B Ped </td> <td>PET </td> <td>01-06-2015 </td> </tr> 
                                     <tr> <td>20 </td> <td>DIVYA K M </td> {/*  <td> 22-05-1991 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 01-06-2016 </td> </tr> 
                                     <tr> <td> 21 </td> <td> GEETHA O C </td> {/*  <td> 22-05-1970 </td> */}{/* <td>Female </td>*/} <td> B A, B Ed </td> <td>PRT </td> <td>06-06-2016 </td> </tr> 
                                     <tr> <td>22 </td> <td>NYCYMOL JOSEPH </td> {/*  <td>23-05-1981 </td>  */}{/*<td>Female </td>*/} <td>M A, B Ed </td> <td>PRT </td> <td>01-06-2017 </td> </tr> 
                                     <tr> <td>23 </td> <td>MATHEW M A </td> {/*  <td>05-08-1968 </td>  */}{/*<td>Male </td>*/} <td>B Sc, B Ed </td> <td> TGT </td> <td>06-06-2019 </td> </tr> 
                                     <tr> <td>24 </td> <td>LAJNA N P </td> {/*  <td>02-05-1979 </td>  */}{/*<td> Female </td>*/} <td> M A, BNYS </td> <td> PRT </td> <td> 06-06-2019 </td> </tr> 
                                     <tr> <td> 25 </td> <td> Sr.LALYKUTTY JOSEPH </td> {/*  <td> 20-05-1976 </td> */}{/* <td> Female </td>*/} <td> B A, B Ed </td> <td> PRT </td> <td> 06-06-2019 </td> </tr> 
                                     <tr> <td> 26 </td> <td> ALEENA THOMAS </td> {/*  <td> 11-05-1994 </td> */}{/* <td> Female </td>*/} <td> M Sc, B Ed </td> <td> TGT </td> <td> 01-06-2022 </td> </tr> 
                                     <tr> <td> 27 </td> <td> AMAL THOMAS </td> {/*  <td> 26-03-1995 </td> */}{/* <td> Male </td>*/} <td> M A, B Ed </td> <td> PRT </td> <td> 01-06-2023 </td> </tr> 
                                     <tr> <td> 28 </td> <td> Sr.JINI MICHAEL P </td> {/*  <td> 10-04-1980 </td> */}{/* <td> Female </td>*/} <td> MSW </td> <td> Wellness Tr </td> <td> 01-06-2023 </td> </tr> 
                                     <tr> <td> 29 </td> <td> Sr.SMITHA ALEX </td> {/*  <td> 20-05-1980 </td> */}{/* <td> Female </td>*/} <td> B A. B Ed </td> <td> PRT </td> <td> 08-01-2024 </td> </tr> 
                                     <tr> <td> 30 </td> <td>SNEHA GEORGE </td> {/*  <td>13-05-1983 </td>  */}{/*<td>Female </td>*/} <td>B A, B Ed </td> <td>PRT </td> <td>01-02-2024 </td> </tr> 
                                     <tr> <td>31 </td> <td>JEEBA M KURIAN </td> {/*  <td>10-04-1990 </td>  */}{/*<td>Female </td>*/} <td>B A, TTC </td> <td>PRT </td> <td>01-06-2024 </td> </tr> 
                                     <tr> <td>32 </td> <td>Sr. ANITTA JOSE </td> {/*  <td>13-12-1989 </td>  */}{/*<td>Female </td>*/} <td>D EL ED </td> <td>PRT </td> <td> 01-06-2024 </td> </tr> 
                                     <tr> <td>33 </td> <td>Sr. JINCY THOMAS </td> {/*  <td>08-04-1982 </td>  */}{/*<td>Female </td>*/} <td>DSE MR </td> <td> Sp.Educator </td> <td> 01-06-2024 </td> </tr> 
                                     <tr> <td> 34 </td> <td> BINSHA M M </td> {/*  <td> 25-03-1989 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 01-06-2024 </td> </tr> 
                                     <tr> <td> 35 </td> <td> ASWATHI G </td> {/*  <td> 16-02-1987 </td> */}{/* <td> Female </td>*/} <td> Sahithyacharya, MBA </td> <td> TGT </td> <td> 01-06-2024 </td> </tr> 
                                     <tr> <td> 36 </td> <td> VINISHA MOHAN K </td> {/*  <td>08-09-1991 </td>  */}{/*<td>Female </td>*/} <td>B Sc, B Ed </td> <td>PRT </td> <td>01-06-2024 </td> </tr> 
                                     <tr> <td>37 </td> <td>CHINJU LAKSHMANAN </td> {/*  <td>03-12-1989 </td>  */}{/*<td>Female </td>*/} <td>B TECH </td> <td>TGT </td> <td> 01-06-2024 </td> </tr> 
                                     <tr> <td> 38 </td> <td> SHRUDHIMOL JOSEPH </td> {/*  <td> 17-03-1986 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td>PRT </td> <td>01-06-2024 </td> </tr> 
                                     <tr> <td>39 </td> <td>ANJU PIOUS </td> {/*  <td>26-01-1995 </td>  */}{/*<td>Female </td>*/} <td>B Sc, B Ed </td> <td>TGT </td> <td>01-06-2024 </td> </tr> 
                                     <tr> <td>40 </td> <td>ANU C </td> {/*  <td>27-12-1993 </td>  */}{/*<td>Female </td>*/} <td>M Sc, B Ed </td> <td>PRT </td> <td>01-06-2024 </td> </tr> 
                                     <tr> <td>41 </td> <td>MARY ANN GOMES </td> {/*  <td>17-04-1982 </td>  */}{/*<td> Female </td>*/} <td> B A, T T C </td> <td>PRT </td> <td> 20-06-2024 </td> </tr> 
                                     <tr> <td> 42 </td> <td> AMRUTHA BALAN </td> {/*  <td> 12-03-1989 </td> */}{/* <td> Female </td>*/} <td> M Tech </td> <td> PRT </td> <td> 26-06-2024 </td> </tr> 
                                     <tr> <td> 43 </td> <td> AMBILY JOSEPH </td> {/*  <td> 28-12-1995 </td> */}{/* <td> Female </td>*/} <td> M A, M Ed </td> <td> PRT </td> <td> 26-06-2024 </td> </tr> 
                                     <tr> <td> 44 </td> <td> MINI N P </td> {/*  <td> 07-05-1981 </td> */}{/* <td> Female </td>*/} <td> M A, B Ed </td> <td> TGT </td> <td> 26-06-2024 </td> </tr> 
                                     <tr> <td> 45 </td> <td> PREETHI M M </td> {/*  <td> 30-05-1994 </td> */}{/* <td> Female </td>*/} <td> C P Ed </td> <td> PET </td> <td> 01-07-2024 </td> </tr> 
                                     <tr> <td> 46 </td> <td> JESNA JOSE </td> {/*  <td> 11-05-1990 </td> */}{/* <td> Female </td>*/} <td> M Sc, B Ed </td> <td> TGT </td> <td> 25-07-2024 </td> </tr>
                                                                          </tbody> 
                                       </table>
                                        </div>
                                         </div>
                                          </div>
                       </div>
                    </div> 
                
             <Footer />
        </>
    );
}

export default Faculties;
