import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { getTopper } from '../Service/Api'; // Ensure getTopper is correctly imported

const Toppers = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch data and set up the years
  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getTopper();
      const filteredGalleryData = galleryData.filter((item) => item.class === 'X');
      setData(filteredGalleryData);

      // Extract unique years from the data
      const uniqueYears = Array.from(new Set(filteredGalleryData.map((item) => item.sessionYear)));
      setYearRanges(uniqueYears);

      // Get the current year in YYYY-YYYY format
      const currentYear = new Date().getFullYear();
      const currentYearStr = `${currentYear}-${currentYear + 1}`;

      // Set the default selected year
      if (uniqueYears.includes(currentYearStr)) {
        setSelectedYear(currentYearStr); // If current year data exists, select it
      } else {
        // Otherwise, select the last available year
        const lastYearStr = uniqueYears[uniqueYears.length - 1];
        setSelectedYear(lastYearStr);
      }
    };

    fetchData();
  }, []);

  // Filter data based on selected year and search query
  useEffect(() => {
    let filteredData = data;

    // Filter by selected year
    if (selectedYear && selectedYear !== 'All') {
      filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
    }

    // Filter by search query
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort data by division in descending order
    filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

    // Update the filtered data state
    setFilteredData(filteredData);
  }, [selectedYear, searchQuery, data]);

  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li>Toppers X</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Toppers X</h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-md-6">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row">
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <div className="col-xl-3 col-lg-4 col-md-4 col-6" key={index}>
                  <div className="toppers-inner">
                    <img
                      src={
                        item.attachments && item.attachments[0]
                          ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                          : "https://webapi.entab.info/api/image/JRPSB/public/Images/trophy.png"
                      }
                      className="img-fluid"
                      alt=" Jai Rani Public School, Balussery"
                    />
                    <h3>{item.name}</h3>
                    <p>{item.stream}</p>
                    <h5>{item.division}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Toppers;
