import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import { postTransferCertificate } from '../Service/Api'; 
const Tc = () => {
  useEffect(() => {
    document.title = 'Transfer Certificate -  Jai Rani Public School, Balussery ';  
}, []);  

const [admission, setAdmission] = useState("");
const [name, setName] = useState("");
const [error, setError] = useState("");

const [loading, setLoading] = useState(false);
const handleAdmissionNoChange = (event) => {
  setAdmission(event.target.value);
  setError(""); // Clear error on user input
};

const handleFullNameChange = (event) => {
  setName(event.target.value);
  setError(""); // Clear error on user input
};

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!name || !admission) {
    setError("Please fill in all fields");
    return;
  }

  setLoading(true);
  try {
    const response = await postTransferCertificate(name, admission);

    if (response && response.data) {
      const filePath = response.data;

      // Declare fileUrl at the beginning of this block
      const fileUrl = `https://webapi.entab.info/api/image/${filePath}`;
      console.log('Constructed File URL:', fileUrl);

      const fetchResponse = await fetch(fileUrl);

      if (!fetchResponse.ok) {
        throw new Error(`Failed to fetch the file. Status: ${fetchResponse.status}`);
      }

      const contentType = fetchResponse.headers.get('Content-Type');
      console.log('File Content-Type:', contentType);

      let fileType = 'unknown';
      if (contentType) {
        if (contentType.includes('pdf')) fileType = 'pdf';
        else if (contentType.includes('image')) fileType = 'image';
      }

      if (fileType === 'unknown') {
        // Fallback to extension-based detection
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        if (fileExtension === 'pdf') fileType = 'pdf';
        else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) fileType = 'image';
      }

      if (fileType === 'unknown') {
        throw new Error('Unsupported file type received');
      }

      const blob = await fetchResponse.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileType === 'pdf' ? 'tc.pdf' : 'tc.jpg';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      setError('Invalid response from the server.');
    }
  } catch (error) {
    console.error('Error during file download:', error);
    setError(error.message || 'Failed to process your request. Please try again later.');
  } finally {
    setLoading(false);
  }
};
  return (
     <>
       <Header />
<div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                 
                 <li>Transfer Certificate</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Transfer Certificate</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <div className="tcblock">
                     <div className="tclist">
                     <ul className="list">
                         <li>Transfer Certificates will be issued only upon an application made on reasonable grounds by the parent/ guardian.</li> 
                         <li>Application form to request for a Transfer Certificate is available here. The completed form should be submitted to the school office.</li> 
                         <li>Certificates will be issued only 3 days after the application is received provided all dues to the school, if any, are cleared.</li> 
                         <li>It will be the parents’ responsibility to check that all details mentioned on the Transfer Certificate are correct. Upon verification, the parent should sign the declaration on the original application form.</li> 
                         <li>Students withdrawn during the term will be required to pay full fees for the remainder of the term.</li>
                     </ul>
                   </div>
                        <div className="tc">
                         
                        <form onSubmit={handleSubmit}>
                        <div className="text_box_sec form-group">
                          <label htmlFor="admission"><p>Admission No.</p></label>
                          <input
                            type="text"
                            placeholder="Admission Number"
                            id="admission"
                            className='form-control'
                            value={admission}
                            onChange={handleAdmissionNoChange}
                          />
                        </div>
                        <div className="text_box_sec form-group">
                          <label htmlFor="name"><p>Student Full Name</p></label>
                          <input
                            type="text"
                            placeholder="Student Name"
                            id="name"
                            className='form-control'
                            value={name}
                            onChange={handleFullNameChange}
                          />
                        </div>
                        <div className="text_box_sec">
                          <input
                            type="submit"
                            name="btnshwtcdetails"
                            value="View Details"
                            id="btnshwtcdetails"
                            className="btn btn-danger viewdetailsbtn"
                          />
                        </div>
                        {error && <p className="error">{error}</p>}
                      </form>
                        </div> 
                     </div>
                     </div>
                </div>
            </div>
        </div>
         <Footer />
     </>
  )
}

export default Tc
