import React, { useEffect } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom'
import Highlights from '../Component/Highlights'
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <div className="header">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Highlights />
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-lg-4 col-md-12'>
                            <div className='header_list'>
                                <ul>
                                    <li><a href="tel:8089147954"><img src="https://webapi.entab.info/api/image/JRPSB/public/Images/phone.gif" />8089147954</a></li>
                                    <li><img src="https://webapi.entab.info/api/image/JRPSB/public/Images/location.gif" /><Link to="https://maps.app.goo.gl/Bbcy7pDY4hT6SHxQ9" target="_blank">Balussery Panangad Rd, Balussery, Kerala 673612 </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className="logo">
                                <NavLink to="/">
                                    <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/logo.png" alt="Jai Rani Public School" className="img-fluid" />
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='button-flex'>
                                <button className='active'><Link to="https://entab.online/JRSBKL" target="_blank"> Parent Portal </Link></button>
                                <button><Link to="/Career">Careers </Link></button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="header-nav">

                                <nav className="navbar navbar-expand-lg">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="main_nav">
                                        <ul className="navbar-nav">
                                            <li className="nav-item active"> <NavLink className="nav-link" to="/">Home </NavLink> </li>
                                           
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">
                                                    <NavLink className="dropdown-item" to="/">About</NavLink>
                                                </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li> <NavLink className="dropdown-item" to="/AboutUs"> About Us</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/TrustMembers"> Trust Members</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/PrincipalMessage"> Principal's Message</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/Faculties"> Faculties</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/Toppers">ToppersX</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/ToppersXII">ToppersXII</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/CabinetLeaders">Cabinet Leaders</NavLink></li>
                                                </ul>
                                            </li>

                                         <li className="nav-item"> <NavLink className="nav-link" to="/Admission">Admission</NavLink> </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">
                                                    <NavLink className="dropdown-item" to="/">Facilities</NavLink>
                                                </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li> <NavLink className="dropdown-item" to="/AtalTinkeringLab"> Atal Tinkering Lab</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/BasketballCourt">Basketball Court</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/CoCurricularActivities">Co-Curricular Activities</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/ComputerLab"> Computer Lab</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/PlayGround"> PlayGround</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/ScienceLab"> Science Lab</NavLink></li>
                                                </ul>
                                            </li>
 
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">
                                                    <NavLink className="dropdown-item" to="/">Academics</NavLink>
                                                </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li> <NavLink className="dropdown-item" to="/Academics"> Academics</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/JRPSB/public/pdf/Syllabus.pdf" target="_blank">Syllabus</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/CoCurricularActivities">Co-Curricular Activities</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/Fee"> Fee</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/JRPSB/public/pdf/School-Annual-Report.pdf" target="_blank"> School Annual Report</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <div className="nav-link" data-bs-toggle="dropdown">
                                                    <NavLink className="dropdown-item" to="/">Gallery</NavLink>
                                                </div>
                                                <ul className="dropdown-menu fade-up">
                                                    <li> <NavLink className="dropdown-item" to="/Gallery"> Gallery</NavLink></li>
                                                    <li> <NavLink className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item public_dis"> <NavLink className="nav-link" to="/MandatoryDisclosure">Public Disclosure</NavLink> </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
