import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
function Admission() { 
    useEffect(() => {
        document.title = "Admission - Jai Rani Public School, Balussery";
    }, []);

    const [visible, setVisible] = useState(true);
    const [age, setAge] = useState(null);
    const [isYesChecked, setIsYesChecked] = useState(false);
  const [isNoChecked, setIsNoChecked] = useState(false);
    const [formData, setFormData] = useState({
        name : "",
        gender   : "",
        dob  : "",
        religion  : "",
        address  : "",
        category  : "",
        aadharCardDetails  : "",
        address1  : "",
        phone2  : "",
        fatherName  : "",
        profession  : "",
        phone  : "",
        email  : "",
        motherName  : "",
        profession1  : "",
        phone1  : "",
        email1  : "",
        siblings: [
        { 
          sibling: '',
          class: '', 
        },
      ],
    });

  
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
  
    useEffect(() => {
      if(formData) {
          console.log('sibling', formData);
      }
    }, [formData]);
  
    const showmore = () => {
      setFormData({
        ...formData,
        siblings: [
          ...formData.siblings,
          {
            sibling: '',
            class: '', 
          },
        ],
      });
    };
  
    const removesibling = (index) => {
      const newSiblings = formData.siblings.filter((_, idx) => idx !== index);
      setFormData({
        ...formData,
        siblings: newSiblings,
      });
    };
  
    const handleChange = (e, index = null) => {
      const { name, value, type, files } = e.target;
      if (name === "dob" && value) {
        const dob = new Date(value);
        const today = new Date();
        let calculatedAge = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            calculatedAge--;
        }

        if (calculatedAge >= 0) {
            setAge(calculatedAge);
        } else {
            setAge(null);
            setErrors({ ...errors, dob: "Invalid date of birth." });
        }
    }
 

      if (type === "file") {
        setFormData({
          ...formData,
          attachments: files[0],
        });
        setErrors({ ...errors, attachments: "" });
      } else if (name.includes("sibling") || name.includes("class")) {
        const newSiblings = [...formData.siblings];
        newSiblings[index][name.split("-")[0]] = value;
        setFormData({ ...formData, siblings: newSiblings });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
      setErrors({ ...errors, [name]: "" });
    };
   
    
    const validateForm = () => {
      const newErrors = {}; 
      if (!formData.name) newErrors.name = "Student Name is required."; 
      if (!formData.gender) newErrors.gender = "Gender is required."; 
      if (!formData.dob) newErrors.dob = "Date of Birth is required."; 
      if (!formData.religion) newErrors.religion = "Religion is required.";  
      if (!formData.category) newErrors.category = "Category is required."; 
      if (!formData.address1) newErrors.address1 = "Postal Address is required."; 
      if (!formData.phone2) newErrors.phone2 = "WhatsApp No  is required."; 
      if (!formData.fatherName) newErrors.fatherName = "Father Name is required."; 
      if (!formData.profession) newErrors.profession = "Father Occupation is required."; 
      if (!formData.phone) newErrors.phone = "Father's Mobile No is required.";  
      if (!formData.motherName) newErrors.motherName = "Mother Name is required."; 
      if (!formData.profession1) newErrors.profession1 = "Mother's Occupation is required."; 
      if (!formData.phone1) newErrors.phone1 = "Mother's Mobile No is required.";  
      return newErrors;
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Log form data
      console.log("Form Data:", formData);
    
      // Validate form data
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
    
      // Prepare payload
      let dataToSend = { ...formData };
      dataToSend.siblings = JSON.stringify(dataToSend.siblings); // Convert siblings array to string
 
    
      // Log the payload being sent
      console.log("Payload being sent:", JSON.stringify(dataToSend));
    
      try {
   




      const response = await fetch("https://script.google.com/macros/s/AKfycbzHoe_X7Eq45pkROFC9EUWJjcaqC-lan8egU_fiPSg7asFxJ6fyvij1QjX-C3y1Rdth/exec", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend), mode: "no-cors",
      });

      console.log("response")
      console.log(response)
      setSuccessMessage("Your form has been submitted successfully.");    
      setFormData({
        name: "",
        gender: "",
        dob: "",
        religion: "",
        address: "",
        category: "",
        aadharCardDetails: "",
        address1: "",
        phone2: "",
        fatherName: "",
        profession: "",
        phone: "",
        email: "",
        motherName: "",
        profession1: "",
        phone1: "",
        email1: "",
        siblings: [
          {
            sibling: "",
            class: "",
          },
        ],
      
      });
      if (!response.ok) {
        console.error("Failed to submit form. Status:", response.status);
      } else {
        const result = await response.json();
        console.log("Form submission result:", result);
        setSuccessMessage("Your form has been submitted successfully.");
      }


      
      if (response.ok) {
        console.log("Request sent. Unable to check response due to no-cors mode.");    
        setSuccessMessage("Your form has been submitted successfully.");    
        // Reset form
        setFormData({
          name: "",
          gender: "",
          dob: "",
          religion: "",
          address: "",
          category: "",
          aadharCardDetails: "",
          address1: "",
          phone2: "",
          fatherName: "",
          profession: "",
          phone: "",
          email: "",
          motherName: "",
          profession1: "",
          phone1: "",
          email1: "",
          siblings: [
            {
              sibling: "",
              class: "",
            },
          ],
        
        });
      }} catch (error) {
        console.error("Error submitting form:", error);
      }
    };
    
    const handleYesChange = () => {
      setIsYesChecked(true);
      setIsNoChecked(false);
    };
  
    const handleNoChange = () => {
      setIsYesChecked(false);
      setIsNoChecked(true);
    };
    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Admission Notice</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Admission Notice</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>
                                <b>Admission Notice for Class-1 (2025-26)<br />
                                   Registration for   Class 1 admissions for the academic year 2025-26 is now closed 
                                </b><br /> 
                            </p>
                            <p> <b> Registration for  Classes 2 to 7 will be Announced in April, Subject to seat availability</b>
                            </p>
                          {   /* 
                           <b>How to Apply:</b> <ul>
                                <li><b>Online:</b> Submit your application through our official website.</li>
                                <li><b>In Person:</b> Collect the application form from the school office starting from 30th December 2024.</li>
                            </ul> 
                           <p><b>Important Dates:</b><br />
                                Last Date for Submission: <b>10th January 2025.</b>
                            </p>
                                <a href="#" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#registrationModal">Registration Form</a>
                        
                          */}
                           
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="registrationModal" tabIndex="-1" aria-labelledby="registrationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="registrationModalLabel">Registration Form</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p><b>Admission to Std I 2025-2026</b></p>
                            <form className='careerform-feilds' action="https://script.google.com/macros/s/AKfycbzHoe_X7Eq45pkROFC9EUWJjcaqC-lan8egU_fiPSg7asFxJ6fyvij1QjX-C3y1Rdth/exec" onSubmit={handleSubmit}>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label name="name" class="form-label">Name of Student <span className="star">*</span></label>
                                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
                                        {errors.name && <div className="error">{errors.name}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="gender" class="form-label">Gender <span className="star">*</span></label>
                                        
                                        <select
                                              className="form-control"
                                              name="gender"
                                              value={formData.gender}
                                              onChange={handleChange}
                                            >
                                            <option value="">Select gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                       </select>
                                       {errors.gender && <div className="error">{errors.gender}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="dob" class="form-label">Date of Birth <span className="star">*</span></label>
                                        <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange} />
                                        {age !== null && ( 
                                          <p>Your Age : {age} Year</p>
                                       )}
                                      
                                        {errors.dob && <div className="error">{errors.dob}</div>}
                                        
                                    </div>
                                     
                                        
                                     
                                    <div class="mb-3">
                                        <label name="religion" class="form-label">Religion <span className="star">*</span> </label>
                                        <input type="text" name="religion" className="form-control" value={formData.religion} onChange={handleChange} />
                                        {errors.religion && <div className="error">{errors.religion}</div>}
                                    </div>
                                   
                                    <div class="mb-3">
                                        <label name="address" class="form-label">Community</label>
                                        <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange} />
                                       
                                    </div>
                                    <div class="mb-3">
                                        <label name="category" class="form-label">Category<span className="star">*</span> </label>
                                        <select
                                              className="form-control"
                                              name="category"
                                              value={formData.category}
                                              onChange={handleChange}
                                            >
                                            <option value="">Select category</option>
                                            <option value="General">General</option>
                                            <option value="OBC">OBC</option>
                                            <option value="SC">SC</option>
                                            <option value="ST">ST</option>
                                        </select>
                                        {errors.category && <div className="error">{errors.category}</div>}
                                    </div>
                    
                                    <div class="mb-3">
                                        <label name="aadharCardDetails" class="form-label">Aadhar No (optional)</label>
                                         <input type="text" name="aadharCardDetails" className="form-control" value={formData.aadharCardDetails} onChange={handleChange} />
                                       
                                         </div>
                    
                    
                                    <div class="mb-3">
                                        <label name="address1" class="form-label">Postal Address <span className="star">*</span></label>
                                        <textarea class="form-control" name="address1" value={formData.address1} onChange={handleChange}></textarea>
                                        {errors.address1 && <div className="error">{errors.address1}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="phone2" class="form-label">WhatsApp No <span className="star">*</span> </label>
                                         <input type="tel" name="phone2" className="form-control" value={formData.phone2} onChange={handleChange} />
                                         {errors.phone2 && <div className="error">{errors.phone2}</div>}
                                    </div>
                                </div>
                    
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label name="fatherName" class="form-label">Name of Father <span className="star">*</span> </label>
                                        <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange} />
                                        {errors.fatherName && <div className="error">{errors.fatherName}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="profession" class="form-label">Father's Occupation  <span className="star">*</span></label>
                                       <input type="text" name="profession" className="form-control" value={formData.profession} onChange={handleChange} />
                                       {errors.profession && <div className="error">{errors.profession}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="phone" class="form-label">Father's Mobile No <span className="star">*</span></label>
                                       <input type="text" name="phone" className="form-control" value={formData.phone} onChange={handleChange} />
                                       {errors.phone && <div className="error">{errors.phone}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="email" class="form-label">Father's Email ID</label>
                                        <input type="text" name="email" className="form-control" value={formData.email} onChange={handleChange} />
                                       
                                    </div>
                                  
                                    <div class="mb-3">
                                        <label name="motherName" class="form-label">Name of Mother<span className="star">*</span></label>
                                       <input type="text" name="motherName" className="form-control" value={formData.motherName} onChange={handleChange} />
                                       {errors.motherName && <div className="error">{errors.motherName}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="profession1" class="form-label">Mother's Occupation <span className="star">*</span></label>
                                        <input type="text" name="profession1" className="form-control" value={formData.profession1} onChange={handleChange} />
                                        {errors.profession1 && <div className="error">{errors.profession1}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="phone1" class="form-label">Mother's Mobile No <span className="star">*</span></label>
                                       <input type="text" name="phone1" className="form-control" value={formData.phone1} onChange={handleChange} />
                                       {errors.phone1 && <div className="error">{errors.phone1}</div>}
                                    </div>
                                    <div class="mb-3">
                                        <label name="email1" class="form-label">Mother's Email ID</label>
                                      <input type="text" name="email1" className="form-control" value={formData.email1} onChange={handleChange} />
                                      
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="mb-3">
                                       <label class="form-label"> Whether any sibling(s) of the applicant is a student of this school?</label> <br/>
                                        <input type="checkbox" name="yes" checked={isYesChecked}  onChange={handleYesChange}/> Yes  &nbsp;
                                        <input type="checkbox"  name="no" checked={isNoChecked}  onChange={handleNoChange} /> No
                                    </div>
                                </div>
                            </div>

                            {isYesChecked && (
                                       <>   
                                            {formData.siblings.map((sibling, index) => (
                                              <div className="row" key={index}>
                                                {/* sibling */}
                                                <div className="col-lg-6">
                                                  <label>Name  </label>
                                                  <input
                                                    type="text"
                                                    name={`sibling-${index}`}
                                                    className="form-control"
                                                    value={sibling.sibling}
                                                    onChange={(e) => handleChange(e, index)}
                                                  />
                                                  
                                                </div>
                    
                                                {/* Subject */}
                                                <div className="col-lg-6">
                                                  <label>Class </label>
                                                  <input
                                                    type="text"
                                                    name={`class-${index}`}
                                                    className="form-control"
                                                    value={sibling.class}
                                                    onChange={(e) => handleChange(e, index)}
                                                  />
                                                  
                                                </div>
                                 
                    
                                                {/* Remove sibling */}
                                                {index > 0 && (
                                                  <div className="col-lg-12">
                                                    <button type="button" onClick={() => removesibling(index)} className="removebtn">
                                                      Remove 
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            ))}

                                          <span onClick={showmore}><i className="bi bi-plus"></i> ADD MORE</span>
                                          </> 
                                   )}
                            
                    
                            
                            <div className="mt-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="declaration" />
                                    <label className="form-check-label" htmlname="declaration">
                                        We parents/guardians hereby declare that the above-mentioned details are true to the best of our knowledge and voluntarily agree to admit our ward in this institution.
                                    </label>
                                </div>
                            </div>
                    
                             <div className="submit">
                            <input type="submit" value="Submit" />
                          </div>

                        </form>
                         {successMessage && (
                            <div className="success-message">
                              <p>{successMessage}</p>
                            </div>
                          )}
                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
        </>
    );
}

export default Admission;
