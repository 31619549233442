import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { form1 } from "../Service/Api";
import { getHeader } from "../config";

const Career = () => {
  useEffect(() => {
    document.title = 'Career - Jai Rani Public School, Balussery';
  }, []);

  const [visible, setVisible] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    dob: "",
    subject: "",
    address: "",
    email: "",
    school: "",
    profession: "",
    mailType: "",
    howKnow: "",
    rate: "",
    school1: "",
    // qualification1: "",
    howKnow1: "", 
 
    experience: "",
    fatherName: "",
    attachments: null,
    qualification1: [
      {
        qualification: '',
        subject: '',
        howKnow: '',
        rate: '',
        school: '',
      },
    ],
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if(formData) {
        console.log('qualification', formData);
    }
  }, [formData]);
 


  useEffect(() => {
    if(formData) {
        console.log('qualification', formData);
    }
  }, [formData]);

  const showmore = () => {
    setFormData({
      ...formData,
      qualification1: [
        ...formData.qualification1,
        {
          qualification: '',
          subject: '',
          howKnow: '',
          rate: '',
          school: '',
        },
      ],
    });
  };

  const removeQualification = (index) => {
    const newQualifications = formData.qualification1.filter((_, idx) => idx !== index);
    setFormData({
      ...formData,
      qualification1: newQualifications,
    });
  };


  const handleChange = (e, index = null) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({
        ...formData,
        attachments: files[0],
      });
      setErrors({ ...errors, attachments: "" });
    } else if (name.includes("qualification") || name.includes("subject")  || name.includes("howKnow") || name.includes("rate") || name.includes("school-")) {
      const newQualifications = [...formData.qualification1];
      newQualifications[index][name.split("-")[0]] = value;
      setFormData({ ...formData, qualification1: newQualifications });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};

    formData.qualification1.forEach((qualification, index) => {
      if (!qualification.qualification) newErrors[`qualification-${index}`] = 'Qualification is required';
      if (!qualification.subject) newErrors[`subject-${index}`] = 'Subject is required';
      if (!qualification.howKnow) newErrors[`howKnow-${index}`] = 'Marks are required';
      if (!qualification.rate) newErrors[`rate-${index}`] = 'Percentage is required';
      if (!qualification.school) newErrors[`school-${index}`] = 'Institution is required';
    });

    if (!formData.name) newErrors.name = "Name is required.";
    // if (!formData.qualification) newErrors.qualification = "Qualification is required.";
    // if (!formData.howKnow) newErrors.howKnow = "Marks are required.";
    // if (!formData.rate) newErrors.rate = "Percentage is required.";
    if (!formData.school1) newErrors.school1 = "Institution is required.";
    // if (!formData.qualification1) newErrors.qualification1 = "Qualification is required.";
    // if (!formData.howKnow1) newErrors.howKnow1 = "Marks are required.";
    // if (!formData.rate1) newErrors.rate1 = "Percentage is required.";
    // if (!formData.school2) newErrors.school2 = "Institution is required.";
    // if (!formData.school) newErrors.school = "Institution is required.";
    if (!formData.profession) newErrors.profession = "Designation is required.";
    if (!formData.fatherName) newErrors.fatherName = "Father / Spouse Name is required.";
    if (!formData.dob) newErrors.dob = "Date of Birth is required.";
    // if (!formData.subject) newErrors.subject = "Subject is required.";
    if (!formData.phone) newErrors.phone = "Contact Number is required.";
    if (!formData.mailType) newErrors.mailType = "Post Applied is required.";
    if (!formData.experience) newErrors.experience = "Number of Years is required.";
    if (!formData.attachments) newErrors.attachments = "Please upload CV (PDF only).";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.email) newErrors.email = "Email is required.";

    // File type validation for attachments
    if (formData.attachments) {
      const validTypes = ['image/jpeg', 'image/jpg', 'application/pdf'];
      if (!validTypes.includes(formData.attachments.type)) {
        newErrors.attachments = "Invalid file type. Only PDF are allowed.";
      }
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const dataToSend = { ...formData };
    dataToSend.qualification1 = JSON.stringify(dataToSend.qualification1);

    // Handle file attachment
    if (formData.attachments) {
      const file = formData.attachments;
      const reader = new FileReader();

      reader.onload = async () => {
        const base64Data = reader.result.split(",")[1]; // Extract base64 data
        dataToSend.attachments = {
          name: file.name,
          type: file.type,
          data: base64Data,
        };

        // Submit the form
        try {
          const response = await fetch(
            "https://script.google.com/macros/s/AKfycbx6UuAnuAHDwoVjQXrxyJoppZYZuDmYgobR_r7MprwUH5rQOgGkIL-NeQnPEMyNe7qm/exec",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataToSend), mode: "no-cors",
            }
          );
          setSuccessMessage("Your Form Submitted Successfully.");
          setFormData({
            name: "",
            address: "",
            dob: "",
            email: "",
            phone: "",
            fatherName: "",           
            
            school1: "",               
            profession: "",
            experience: "",              
            mailType: "",                   
            attachments: null,
            qualification1: [
              {


                qualification: '',
                subject: '',
                howKnow: '',
                rate: '',
                school: '',
                 
              },
            ],
          });
          if (response.ok) {
            const result = await response.json();
            console.log("Form submitted successfully:", result);

            // Reset form fields and show success message
           
           
          } else {
            const errorText = await response.text();
            console.error("Form submission failed:", errorText);
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      };

      reader.readAsDataURL(file); // Convert file to Base64
    }
  };
  
  
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li>Career</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Career</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Thank you for your interest in pursuing a fruitful career with Jai Rani SABS Public School. Please complete this form with all your relevant details and also attach your latest resume.</p>
              <p>We will contact you if your profile is suitable for any current opportunities.</p>
              <p>Please make sure all entries are correct before submitting, once submitted the form cannot be edited or changed.</p>
            </div>
          </div>

          <form className='careerform-feilds' action="https://script.google.com/macros/s/AKfycbx6UuAnuAHDwoVjQXrxyJoppZYZuDmYgobR_r7MprwUH5rQOgGkIL-NeQnPEMyNe7qm/exec"  onSubmit={handleSubmit}>
            <div className="row">
              {/* Name */}
              <div className="col-lg-6">
                <label>Name</label>
                <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>

              {/* Address */}
              <div className="col-lg-6">
                <label>Address</label>
                <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange} />
                {errors.address && <div className="error">{errors.address}</div>}
              </div>

              {/* Date of Birth */}
              <div className="col-lg-6">
                <label>Date of Birth</label>
                <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange} />
                {errors.dob && <div className="error">{errors.dob}</div>}
              </div>

              {/* Email */}
              <div className="col-lg-6">
                <label>Email</label>
                <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              {/* Phone */}
              <div className="col-lg-6">
                <label>Contact Number</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone" className="form-control" maxLength={10} value={formData.phone} onChange={handleChange} />
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>

              {/* Father/Spouse Name */}
              <div className="col-lg-6">
                <label>Father / Spouse Name</label>
                <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange} />
                {errors.fatherName && <div className="error">{errors.fatherName}</div>}
              </div>
            </div>

            {/* Educational Qualification */}
            <h3>Educational Qualification <span onClick={showmore}><i className="bi bi-plus"></i></span></h3>
            {formData.qualification1.map((qualification, index) => (
              <div className="row" key={index}>
                {/* Qualification */}
                <div className="col-lg-6">
                  <label>Qualification</label>
                  <input
                    type="text"
                    name={`qualification-${index}`}
                    className="form-control"
                    value={qualification.qualification}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`qualification-${index}`] && <div className="error">{errors[`qualification-${index}`]}</div>}
                </div>

                {/* Subject */}
                <div className="col-lg-6">
                  <label>Subject</label>
                  <input
                    type="text"
                    name={`subject-${index}`}
                    className="form-control"
                    value={qualification.subject}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`subject-${index}`] && <div className="error">{errors[`subject-${index}`]}</div>}
                </div>

                {/* Marks */}
                <div className="col-lg-6">
                  <label>Marks</label>
                  <input
                    type="text"
                    name={`howKnow-${index}`}
                    className="form-control"
                    value={qualification.howKnow}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`howKnow-${index}`] && <div className="error">{errors[`howKnow-${index}`]}</div>}
                </div>

                {/* Percentage */}
                <div className="col-lg-6">
                  <label>Percentage</label>
                  <input
                    type="text"
                    name={`rate-${index}`}
                    className="form-control"
                    value={qualification.rate}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`rate-${index}`] && <div className="error">{errors[`rate-${index}`]}</div>}
                </div>

                {/* Institution */}
                <div className="col-lg-6">
                  <label>Institution</label>
                  <input
                    type="text"
                    name={`school-${index}`}
                    className="form-control"
                    value={qualification.school}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`school-${index}`] && <div className="error">{errors[`school-${index}`]}</div>}
                </div>

                {/* Remove Qualification */}
                {index > 0 && (
                  <div className="col-lg-12">
                    <button type="button" onClick={() => removeQualification(index)} className="removebtn">
                      Remove 
                    </button>
                  </div>
                )}
              </div>
            ))}

            {/* Work Experience */}
            <h3>Work Experience</h3>
            <div className="row">
              <div className="col-lg-6">
                <label>Institution</label>
                <input
                  type="text"
                  name="school1"
                  className="form-control"
                  value={formData.school1}
                  onChange={handleChange}
                />
                {errors.school1 && <div className="error">{errors.school1}</div>}
              </div>

              <div className="col-lg-6">
                <label>Designation</label>
                <input
                  type="text"
                  name="profession"
                  className="form-control"
                  value={formData.profession}
                  onChange={handleChange}
                />
                {errors.profession && <div className="error">{errors.profession}</div>}
              </div>

              <div className="col-lg-6">
                <label>No of Years</label>
                <input
                  type="text"
                  name="experience"
                  className="form-control"
                  value={formData.experience}
                  onChange={handleChange}
                />
                {errors.experience && <div className="error">{errors.experience}</div>}
              </div>

              {/* Post Applied For */}
              <div className="col-lg-6">
                <label>Post Applied For</label>
                <select
                  className="form-control"
                  name="mailType"
                  value={formData.mailType}
                  onChange={handleChange}
                >
                  <option value="">-Please Select-</option>
                  <option value="English">English</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Science">Science</option>
                  <option value="Social Science">Social Science</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="Physics">Physics</option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="Biology">Biology</option>
                  <option value="Computer Science">Computer Science</option>
                  <option value="Clerk">Clerk</option>
                  <option value="Menial staff">Menial staff</option>
                  <option value="Primary Teacher">Primary Teacher</option>
                  <option value="Physical Education">Physical Education</option>
                  <option value="Counsellor">Counsellor</option>
                  <option value="Driver">Driver</option>
                </select>
                {errors.mailType && <div className="error">{errors.mailType}</div>}
              </div>

              {/* CV Upload */}
              <div className="col-lg-6">
                <label>Upload CV (Only PDF Format)</label>
                <input
                  type="file"
                  name="attachments"
                  accept=".pdf, .jpg, .jpeg"
                  className="form-control"
                  onChange={handleChange}
                />
                {errors.attachments && <div className="error">{errors.attachments}</div>}
              </div>
            </div>
            {/* Submit Button */}
            <div className="submit">
            <input type="submit" value="Submit" />
          </div>
          </form>

          {/* Success Message */}
          {successMessage && (
            <div className="success-message">
              <p>{successMessage}</p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
