import Home from './Pages/Home'
import PrincipalMessage from './Pages/PrincipalMessage'
import Toppers from './Pages/Toppers'
import ToppersXII from './Pages/ToppersXII'
import News from './Pages/News'
import Events from './Pages/Events'
import Tc from './Pages/Tc'
import Gallery from './Pages/Gallery'
import SubGallery from './Pages/SubGallery'
import VideoGallery from './Pages/VideoGallery'
import Pphelp from './Pages/Pphelp'
import ErpSoftware from './Pages/ErpSoftware'
import ContactUs from './Pages/ContactUs'
import AboutUs from './Pages/AboutUs'
import VisionMission from './Pages/VisionMission'
import CoCurricularActivities from './Pages/CoCurricularActivities'
import BasketballCourt from './Pages/BasketballCourt'
import AtalTinkeringLab from './Pages/AtalTinkeringLab'
import PlayGround from './Pages/PlayGround'
import Academics from './Pages/Academics'
import Faculties from './Pages/Faculties'
import ComputerLab from './Pages/ComputerLab'
import ScienceLab from './Pages/ScienceLab'
import Fee from './Pages/Fee'
import TrustMembers from './Pages/TrustMembers'
import MandatoryDisclosure from './Pages/MandatoryDisclosure'
import Career from './Pages/Career'
import CabinetLeaders from './Pages/CabinetLeaders'
import Admission from './Pages/Admission'

import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/PrincipalMessage" element={<PrincipalMessage />} />
          <Route path="/Toppers" element={<Toppers />} />
          <Route path="/ToppersXII" element={<ToppersXII />} />
          <Route path="/News" element={<News />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Tc" element={<Tc />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/SubGallery" element={<SubGallery />} />
          <Route path="/VideoGallery" element={<VideoGallery />} />
          <Route path="/Pphelp" element={<Pphelp />} />
          <Route path="/ErpSoftware" element={<ErpSoftware />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/VisionMission" element={<VisionMission />} />
          <Route path="/CoCurricularActivities" element={<CoCurricularActivities />} />
          <Route path="/BasketballCourt" element={<BasketballCourt />} />
          <Route path="/AtalTinkeringLab" element={<AtalTinkeringLab />} />
          <Route path="/PlayGround" element={<PlayGround />} />
          <Route path="/Academics" element={<Academics />} />
          <Route path="/Faculties" element={<Faculties />} />
          <Route path="/ComputerLab" element={<ComputerLab />} />
          <Route path="/ScienceLab" element={<ScienceLab />} />
          <Route path="/Fee" element={<Fee />} />
          <Route path="/TrustMembers" element={<TrustMembers />} />
          <Route path="/MandatoryDisclosure" element={<MandatoryDisclosure />} />
          <Route path="/CabinetLeaders" element={<CabinetLeaders />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/Admission" element={<Admission />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
