import React, { useEffect, useState } from "react";
import { getOnlineRegistration } from "../Service/Api";
import { Link } from 'react-router-dom'
const OnlineReg = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOnlineRegistration();
        setData(data);
      } catch (error) {
        console.error("Error fetching online registration data:", error);
      }
    };
    fetchData();
  }, []);
  const emptyArray = []
  return (
    <>
      {data.length > 0 ? (
        data.map((item, index) => (
          <div className="OnlineR" key={index}>
            <span className="bounce1">
              <Link to="https://entab.online/Registration/OnlineRegistrationForm" target="_blank" className="parantlogo ">  {item.title}  </Link>
              {item.attachments ? (<Link to={`https://webapi.entab.info/api/image?url=${item.attachments[0]}`} target="_blank" className="">
                <i className="bi bi-paperclip"></i></Link>) : (<></>)}

            </span>
          </div>
        ))
      ) : (
        emptyArray.map((item, index) => <div key={index}></div>)
      )}
    </>
  )
}

export default OnlineReg
